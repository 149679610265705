import React from 'react'
import { graphql } from 'gatsby'
import JsonTable from 'ts-react-json-table'
import { kebabCase } from 'utils/kebab-case'

const ProjectStatsPage = ({ data }) => {
	// Filter out items without Category or Project defined
	const gallery = data.prismicProjectsGallery.data.list.filter(
		({ category, project }) => {
			return category && project?.document?.id
		}
	)
	const galleryDict = {}
	const projectPlaceholder = {
		exterior: 0,
		interior: 0,
	}

	gallery.forEach((item) => {
		const projectId = item.project.document.id

		if (!galleryDict.hasOwnProperty(projectId)) {
			galleryDict[projectId] = { ...projectPlaceholder }
		}

		if (item.category === 'Exterior') {
			galleryDict[projectId].exterior++
		}
		if (item.category === 'Interior') {
			galleryDict[projectId].interior++
		}
	})
	// console.log(galleryDict)
	const projectList = data.allPrismicProject.nodes.map(
		({ uid, id, data, _previewable, tags }) => {
			return {
				uid,
				id,
				...data,
				prismicId: _previewable,
				tags: tags.filter(
					(tag) => ['Animation', 'Interior', 'Exterior'].indexOf(tag) < 0
				),
			}
		}
	)
	projectList.forEach((project) => {
		if (galleryDict.hasOwnProperty(project.id)) {
			project.exterior = galleryDict[project.id].exterior
			project.interior = galleryDict[project.id].interior
		}
		if (project.body.length) {
			const seoSlice = project.body.filter(
				({ slice_type }) => slice_type === 'seo_fields'
			)
			if (seoSlice.length) {
				const { title, description, bottom_text } = seoSlice[0].primary

				project.seo_title = title
				project.seo_description = description
				project.seo_bottom_text = bottom_text.html
			}
		}
	})
	projectList.sort((a, b) => {
		const totalA = (a.exterior || 0) + (a.interior || 0)
		const totalB = (b.exterior || 0) + (b.interior || 0)

		return totalB - totalA
	})
	projectList.forEach((project, index) => {
		project.index = index + 1
		project.own_images = project.list.filter((item) => item.gallery_image.url).length
	})
	// console.log(projectList)

	const columns = [
		{ label: 'Index', key: 'index' },
		{
			label: 'Prismic',
			key: 'prismicId',
			cell: (row, columnKey) => (
				// eslint-disable-next-line
				<a
					href={`https://cuub.prismic.io/documents~k=project&b=working&c=published&l=en-gb/${row[columnKey]}/`}
					target="_blank"
					className="accent-hover underline"
				>
					Edit
				</a>
			),
		},
		{
			label: 'Url part - Click to open page',
			key: 'uid',
			cell: (row, columnKey) => (
				// eslint-disable-next-line
				<a
					href={`/projects/${row[columnKey]}/`}
					target="_blank"
					className="accent-hover underline"
				>
					{row[columnKey]}
				</a>
			),
		},
		{ label: 'Displayed Name', key: 'project_name' },
		{ label: 'SEO Title', key: 'seo_title' },
		{ label: 'SEO Description', key: 'seo_description' },
		{ label: 'SEO Bottom Text', key: 'seo_bottom_text' },
		{
			label: 'Tags',
			key: 'tags',
			cell: (row, columnKey) =>
				row[columnKey].map((tag) => (
					// eslint-disable-next-line
					<a
						key={tag}
						href={`/projects/tag/${kebabCase(tag)}/`}
						target="_blank"
						className="accent-hover mb-2 mr-2 inline-block text-xs underline"
					>
						{tag}
					</a>
				)),
		},
		{ label: 'Location', key: 'location' },
		{ label: 'Architect', key: 'architect' },
		{ label: 'Ext in Gallery', key: 'exterior' },
		{ label: 'Int in Gallery', key: 'interior' },
		{ label: 'Own Images', key: 'own_images' },
	]

	return (
		<JsonTable
			className="text-sm"
			rows={projectList}
			columns={columns}
			settings={{
				cellClass: (currentClass, columnKey, rowData) =>
					`p-1 border-b border-gray-300 ${
						columnKey.indexOf('seo') > -1 ? 'text-xs' : ''
					}`,
				headerClass: () => 'py-2 pr-2 pl-1 bg-gray-100 text-left text-xs',
			}}
		/>
	)
}

export const query = graphql`
	fragment PrismicProjectsGalleryFragment on PrismicProjectsGallery {
		_previewable
		data {
			title
			subtitle
			bold_paragraph {
				richText
			}
			list {
				category
				description
				image: gallery_image {
					alt
					dimensions {
						height
						width
					}
					url
				}
				project {
					document {
						... on PrismicProject {
							id
						}
					}
				}
			}
		}
	}
	query projectStatsQuery {
		allPrismicProject {
			totalCount
			nodes {
				data {
					architect
					location
					project_name
					list {
						gallery_image {
							url
						}
						category
					}
					body {
						... on PrismicProjectDataBodySeoFields {
							slice_type
							primary {
								description
								title
								bottom_text {
									richText
									html
								}
							}
						}
					}
				}
				tags
				id
				uid
			}
		}
		prismicProjectsGallery {
			...PrismicProjectsGalleryFragment
		}
	}
`

export default ProjectStatsPage
